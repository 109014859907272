<template lang="pug">
    v-app
        v-app-bar(app dense dark color="deep-purple accent-4")
            v-app-bar-nav-icon(@click.stop="drawer = !drawer")
            v-btn.mr-5(v-if="appBackLink" fab small text outlined :to="appBackLink")
                v-icon mdi-keyboard-backspace
            v-toolbar-title
                template(v-if="appBarTitle == 'loading'")
                    v-progress-linear(indeterminate style="width:200px;")
                template(v-else) {{ appBarTitle }}

        v-navigation-drawer(v-model="drawer" absolute temporary)
            v-list(nav dense)
                v-list-item-group(v-model="group" active-class="deep-purple--text text--accent-4")
                    v-list-item(v-for="(item,index) in menuItems" :key="index" :to="item.path")
                        v-list-item-icon
                            v-icon {{ item.icon }}
                        v-list-item-content
                            v-list-item-title(v-text="item.name")
                    v-list-item(@click="exit()")
                        v-list-item-icon
                            v-icon mdi-logout
                        v-list-item-content
                            v-list-item-title Выход

        

        v-main(app)
            router-view
        
</template>



<script>
    import { NavItems as items } from './nav/NavItems'
    import { mapState, mapActions } from "vuex";

    export default {
        data: () => ({
           drawer:false,
           group: null
        }),
        computed: {
            ...mapState(['appBarTitle','appBackLink']),
            menuItems () {
                return items
            }
        },
        methods: {
            ...mapActions("authentication", ["logout"]),
            exit(){
                this.logout()
            }
        }
    }
</script>

