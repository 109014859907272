import { userService } from '@/_services'

export const user = {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    users: state => {
      if (state.list.length == 0) {
        userService.list().then((data) => {
          data.sort((u1, u2) => u1.name > u2.name ? 1 : u1.name < u2.name ? -1 : 0);
          state.list = data
        })
      }
      return state.list
    }
  },
}