import { $_app_server } from '@/_helpers'

export const networkService = {
  loadMyNetwork,
  loadNetwork,
  loadByProject,
  loadForReport
}

let resp = (response) => response.data

function loadMyNetwork(projectid) {
  return $_app_server.get(`network/my?projectid=${projectid}`).then(resp)
}

function loadNetwork(id) {
  return $_app_server.get(`network/${id}`).then(resp)
}

function loadByProject(id) {
  return $_app_server.get(`network/list?project_id=${id}`).then(resp)
}

function loadForReport(id, start, end, cities) {
  return $_app_server.get(`network/report?project_id=${id}&start=${start}&end=${end}&cities=${cities}`).then(resp)
}