import Vue from 'vue'

export const network = {
  namespaced: true,
  state: {
    selectedNetwork: null,
    networks: {}
  },
  actions: {},
  mutations: {
    setSelectedNetwork(state, network) {
      state.selectedNetwork = network
    },
    setNetworkByProject(state, { id, networks }) {
      Vue.set(state.networks, id, networks)
    },
    resetState(state) {
      state.selectedNetwork = null
      state.networks = {}
    }
  }
}