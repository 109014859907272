import { $_app_server } from '@/_helpers'

export const productService = {
  loadPointProducts,
  loadPointActionProducts,
  getProductReportData
}

function loadPointProducts(tradepointid, categoryid) {
  let query = `?tradepointid=${tradepointid}&categoryid=${categoryid}`
  return $_app_server.get(`product/getpointproducts${query}`)
    .then(response => {
        return response.data
    })
}

function loadPointActionProducts(tradepointid) {
  let query = `?tradepointid=${tradepointid}`
  return $_app_server.get(`product/getpointactionproducts${query}`)
    .then(response => {
        return response.data
    })
}

function getProductReportData(tradepointid,id) {
  return $_app_server.get(`product/getproductreportdata?tradepointid=${tradepointid}&id=${id}`)
    .then(response => {
        return response.data
    })
}