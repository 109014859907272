export const project = {
  namespaced: true,
  state: {
    selectedProject: null,
    projects: []
  },
  getters: {},
  actions: {},
  mutations: {
    setSelectedProject(state, project) {
      state.selectedProject = project
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    resetState(state) {
      state.selectedProject = null
      state.projects = []
    }
  }
}