<template lang="pug">
     v-container
        router-view(v-show="!loading")

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}
</template>

<script>
    import Vue from 'vue'
    import { mapMutations } from "vuex";

    export default {
        data: () => ({
            loading: false,
            error: null
        }),
        created() {
            let el = this;
            Vue.mixin({
                methods: {
                loadingHead(method, params, success) {
                    this.setAppBarTitle('loading')
                    method(params)
                        .then(success)
                        .catch(error => {
                            this.setAppBarTitle(this.error)
                        })
                },
                loadingData(method, params, success, fail) {
                    el.loading = true
                    method(params)
                        .then(success)
                        .catch(error => {
                            this.error = error;
                            if (fail != null) fail(error);
                        })
                        .finally(() => {
                            el.loading = false
                        })
                }
                }
            })
        },
        destroyed() {
            Vue.mixin({
                methods: {
                    loadingHead(method, params, success) {},
                    loadingData(method, params, success) {}
                }
            })
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
        }
    }
</script>