import access from './access.route'
import merch from './merch.route'
import coordinator from './coordinator.route'

import NotFound from '@/pages/common/NotFoundPage.vue'

const HomeLayout = () => import('@/layouts/HomeLayout.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/access',
    meta: {
      label : "Главная",
      title : "Главная"
    },
    component: HomeLayout,
    alias: ['/home', '/index']
  },
  {
    path: '*', 
    component: NotFound
  },
  access,
  merch,
  coordinator
]

export default routes
