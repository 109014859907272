import { $_app_server } from '@/_helpers'

export const categoryService = {
  getcategories,
  getpointcategories,
  loadCategory
}

function getcategories() {
  return $_app_server.get(`category/categories`)
    .then(response => {
        return response.data
    })
}

function getpointcategories(id) {
  return $_app_server.get(`category/pointcategories/${id}`)
    .then(response => {
        return response.data
    })
}

function loadCategory(id) {
  return $_app_server.get(`category/${id}`)
    .then(response => {
        return response.data
    })
}