import { $_app_server } from '@/_helpers'

export const projectService = {
  getMyProjects,
  loadProject,
  list
}

function getMyProjects() {
  return $_app_server.get("project/my")
    .then(response => {
        return response.data
    })
}

function loadProject(id) {
  return $_app_server.get(`project/${id}`)
    .then(response => {
        return response.data
    })
}

function list() {
  return $_app_server.get("project/list").then(response => response.data)
}