export const tradepoint = {
  namespaced: true,
  state: {
    selectedPoint: null,
    cities: []
  },
  getters: {},
  actions: {},
  mutations: {
    setSelectedPoint(state, point) {
      state.selectedPoint = point
    },
    setCities(state, cities) {
      state.cities = cities
    },
    resetState(state) {
      state.selectedPoint = null
      state.cities = []
    }
  }
}