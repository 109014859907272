import { $_app_server } from '@/_helpers'

export const motivationalReportService = {
    list,
    getMotivationalReport,
    addPhotoToReport,
    deleteMotivationalPhoto,
    excel,
    editReportDate,
    editDmpComment,
    zip
}

let resp = (response) => response.data

function list(id, params) {
  return $_app_server.get(`motivationalReport/list/${id}`, {
    params: params
  }).then(resp)
}

function getMotivationalReport(id, date) {
    return $_app_server.get(`motivationalReport/${id}/${date}`).then(resp)
}

function addPhotoToReport(id, reportPhotos) {
  return $_app_server.put(`motivationalReport/editDmpReport/${id}`, reportPhotos).then(resp)
}

function deleteMotivationalPhoto(reportId, imageUuid) {
  return $_app_server.delete(`motivationalReport/${reportId}/photo/${imageUuid}`).then(resp)
}

function excel(projectId, params) {
  return $_app_server.get(`motivationalReport/${projectId}/export/excel`, {
    responseType: 'blob',
    params: params
  })
}

function editReportDate(reportId, oldDate, newDate){
  return $_app_server.put(`motivationalReport/editDateDmpReport/${reportId}/${oldDate}/${newDate}`).then(resp)
}

function editDmpComment(motivationalDmpReportId, comment){
  console.log(comment);
  return $_app_server.put(`motivationalReport/editDmpComment/${motivationalDmpReportId}/${comment}`).then(resp)
}

function zip(projectId, params) {
  return $_app_server.get(`motivationalReport/export/zip/${projectId}`, {
    responseType: 'blob',
    params: params
  })
}

