import DefaultLayout from '@/layouts/DefaultLayout.vue'

const Projects = () => import('@/pages/merch/project/Projects.vue')
const MyprojectList = () => import('@/pages/merch/project/MyprojectList.vue')
const Project = () => import('@/pages/merch/project/Project.vue')
const Network = () => import('@/pages/merch/project/Network.vue')
const Point = () => import('@/pages/merch/points/Point.vue')
const Category = () => import('@/pages/merch/category/Category.vue')

export default {
    path: '/project',
    component: DefaultLayout,
    children: [
        {
            path: '',
            component: Projects,
            children: [
                {
                    path: '',
                    name: 'Проекты',
                    meta: {
                        label : "Проекты",
                        title : "Проекты"
                    },
                    component: MyprojectList
                },
                {
                    path: ':id',
                    name: 'Проект',
                    meta: {
                        label : "Проект",
                        title : "Проект",
                        breadcrumb: [
                            {name: 'Проекты', link: '/project'}
                        ]
                    },
                    component: Project
                },
                {
                    path: ':id/network/:nid',
                    name: 'Сеть',
                    meta: {
                        label : "Сеть",
                        title : "Сеть",
                        breadcrumb: [
                            {name: 'Проект', link: '/project/:id'}
                        ]
                    },
                    component: Network
                },
                {
                    path: ':id/network/:nid/tradepoint/:pid',
                    name: 'Точка',
                    meta: {
                        label : "Точка",
                        title : "Точка",
                        breadcrumb: [
                            {name: 'Сеть', link: '/project/:id/network/:nid'}
                        ]
                    },
                    component: Point
                },
                {
                    path: ':id/network/:nid/tradepoint/:pid/category/:cid',
                    name: 'Категория',
                    meta: {
                        label : "Категория",
                        title : "Категория",
                        breadcrumb: [
                            {name: 'Точка', link: '/project/:id/network/:nid/tradepoint/:pid'}
                        ]
                    },
                    component: Category
                }
            ]
        }
    ]
}