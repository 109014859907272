import { $_app_server } from '@/_helpers'

export const reportService = {
  getReportByPointId,
  sendReportData,
  sendPreReport,
  sendReport,
  list,
  edit,
  get,
  newPoint,
  remove,
  editData,
  savePhoto,
  saveDataPhoto,
  deletePhoto,
  deleteDataPhoto,
  loadByNetwork,
  excelByNetwork,
  excelByNetworkLink,
  zip,
  zipLink,
  excel,
  checkUpload
}

let resp = (response) => response.data

function getReportByPointId(tradepointid) {
  return $_app_server.get(`report/reportbypoint/${tradepointid}`).then(resp)
}

function sendReportData(data) {
  return $_app_server.post(`report/savereportdata`, data).then(resp)
}

function sendPreReport(data) {
  return $_app_server.post(`report`, data).then(resp)
}

function sendReport(report) {
  return $_app_server.post(`report/savereport`, { uuid: report.uuid }).then(resp)
}
// ?networks=${networks}&cities=${cities}&start=${start}&end=${end}&user=${user}&page=${page}&size=${size}
function list(id, params) {
  return $_app_server.get(`report/list/${id}`, {
    params: params
  }).then(resp)
}

function edit(uuid, params) {
  return $_app_server.put(`report/${uuid}`, params).then(resp)
}

function get(uuid) {
  return $_app_server.get(`report/${uuid}`).then(resp)
}

function newPoint(params) {
  return $_app_server.get(`report/new`, {
    params: params
  }).then(resp)
}

function remove(uuid) {
  return $_app_server.delete(`report/${uuid}`).then(resp)
}

function editData(uuid, product, params) {
  return $_app_server.put(`report/${uuid}/product/${product}`, params).then(resp)
}

function savePhoto(uuid, data) {
  return $_app_server.put(`report/${uuid}/photo`, data).then(resp)
}

function saveDataPhoto(uuid, data, product) {
  return $_app_server.put(`report/${uuid}/photo/product/${product}`, data).then(resp)
}

function deletePhoto(uuid, data) {
  return $_app_server.delete(`report/${uuid}/photo/${data}`).then(resp)
}

function deleteDataPhoto(uuid, data, product) {
  return $_app_server.delete(`report/${uuid}/photo/${data}/product/${product}`).then(resp)
}

function loadByNetwork(project, id, start, end, cities) {
  return $_app_server.get(`report/network/${id}?project_id=${project}&start=${start}&end=${end}&cities=${cities}`).then(resp)
}

function excelByNetwork(id, params) {
  return $_app_server.get(`report/network/${id}/excel`, {
    responseType: 'blob',
    params: params
  })
}

function zip(id, params) {
  return $_app_server.get(`report/${id}/zip`, {
    responseType: 'blob',
    params: params
  })
}

function excelByNetworkLink(id, params) {
  return $_app_server.get(`report/network/${id}/excel`, {
    params: params
  })
}

function zipLink(id, params) {
  return $_app_server.get(`report/${id}/zip`, {
    params: params
  })
}

function excel(id, params) {
  return $_app_server.get(`report/${id}/excel`, {
    responseType: 'blob',
    params: params
  })
}

function checkUpload(id) {
  return $_app_server.get(`report/upload/${id}`).then(resp)
}
