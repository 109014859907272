import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ValidLayout from '@/layouts/ValidLayout.vue'

const Search = () => import('@/pages/coordinator/report/Search.vue')
const Card = () => import('@/pages/coordinator/report/Card.vue')
const Create = () => import('@/pages/coordinator/report/Create.vue')
const Download = () => import('@/pages/coordinator/report/Download.vue')
const Retailer = () => import('@/pages/coordinator/report/Retailer.vue')
const Actions = () => import('@/pages/coordinator/report/action/List.vue')
const Action = () => import('@/pages/coordinator/report/action/Card.vue')
const MotivationalReport = () => import('@/pages/coordinator/report/DownloadMotivationalReport.vue')
const MotivationalCard = () => import('@/pages/coordinator/report/MotivationalCard.vue')


export default {
  path: '/report',
  component: DefaultLayout,
  children: [
    {
      path: '',
      component: ValidLayout,
      children: [
        {
          path: 'control',
          name: 'control',
          meta: {
              label : "Проверка отчётности",
              title : "Проверка отчётности"
          },
          component: Search
        },
        {
          path: 'download',
          name: 'download',
          meta: {
              label : "Скачать отчётность",
              title : "Скачать отчётность"
          },
          component: Download
        },
        {
          path: 'actions',
          name: 'actions',
          meta: {
              label : "Акции",
              title : "Акции"
          },
          component: Actions,
          props: true
        },
        {
          path: 'action/:id',
          name: 'action',
          meta: {
              label : "Отчёт по акции",
              title : "Отчёт по акции"
          },
          component: Action,
          props: true
        },
        {
          path: 'motivational',
          name: 'motivationalReport',
          meta: {
              label : "Мотивационный отчёт",
              title : "Мотивационный отчёт"
          },
          component: MotivationalReport
        },
        {
          path: 'motivationalReport/:id/:reportDate',
          name: 'motivational',
          meta: {
              label : "Мотивационный отчёт",
              title : "Мотивационный отчёт"
          },
          component: MotivationalCard,
          props: true
        },
        {
          path: ':id',
          name: 'report',
          meta: {
              label : "Отчёт",
              title : "Отчёт"
          },
          component: Card,
          props: true
        },
        {
          path: 'create/:id/project/:project',
          name: 'create',
          meta: {
              label : "Отчёт",
              title : "Отчёт"
          },
          component: Create,
          props: true
        },
        {
          path: 'download/:id',
          name: 'downloaditem',
          meta: {
              label : "Скачать отчётность",
              title : "Скачать отчётность"
          },
          component: Retailer,
          props: (route) => {
            route.params['cities'] = route.query.cities || route.params.cities
            route.params['start'] = route.query.start || route.params.start
            route.params['end'] = route.query.end || route.params.end
            route.params['project'] = route.query.project || route.params.project
            return route.params
          }
        },
      ]
  },
  ]
}