export let Role = {
  admin: 'admin'
}

export let NavItems = [
  // {
  //   name: 'Проекты',
  //   path: '/project',
  //   icon: 'mdi-store'
  // },
  {
    name: 'Проверка отчётности',
    path: '/report/control',
    icon: 'mdi-briefcase-check'
  },
  {
    name: 'Скачать отчётность',
    path: '/report/download',
    icon: 'mdi-download-circle'
  },
  {
    name: 'Акции',
    path: '/report/actions',
    icon: 'mdi-sale'
  },
  {
    name: 'Мотивационный отчёт',
    path: '/report/motivational',
    icon: 'mdi-download'
  },
]