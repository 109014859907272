import { $_app_server } from '@/_helpers'

export const tradepointService = {
  getmytradepoints,
  loadPoint,
  loadCities
}

let resp = (response) => response.data

function getmytradepoints(networkid) {
  return $_app_server.get(`tradepoint/getmytradepoints?networkid=${networkid}`).then(resp)
}

function loadPoint(id) {
  return $_app_server.get(`tradepoint/${id}`).then(resp)
}

function loadCities(project, networks) {
  return $_app_server.get(`tradepoint/cities?projectid=${project}&networks=${networks}`).then(resp)
}