import { $_app_server } from '@/_helpers'

export const tradeactionService = {
  list,
  report,
  excel,
  reportremove
}

let resp = (response) => response.data

function list() {
  return $_app_server.get(`action/list`).then(resp)
}

function report(id) {
  return $_app_server.get(`action/${id}/report`).then(resp)
}

function reportremove(id) {
  return $_app_server.delete(`action/${id}/report`).then(resp)
}

function excel(id, params) {
  return $_app_server.get(`action/${id}/export`, {
    responseType: 'blob',
    params: params
  })
}